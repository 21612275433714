<script setup>
import {ref, onMounted, onUnmounted, computed} from 'vue';

const props = defineProps({
    message: {
        type: String,
        required: true,
    },
    variant: {
        type: String,
        default: 'primary',
    },
});

const emit = defineEmits(['hidden']);

const toastElement = ref(null);
let toastInstance = null;

const toastClasses = computed(() => {
    return `toast align-items-center text-bg-${props.variant} border-${props.variant} mb-3 fs-5`;
});

const closeToast = () => {
    if (toastInstance) {
        toastInstance.hide();
    }
};

onMounted(async () => {
    if (typeof window !== 'undefined') {
        const {Toast} = await import('bootstrap');

        toastInstance = new Toast(toastElement.value);
        toastInstance.show();

        toastElement.value.addEventListener('hidden.bs.toast', () => emit('hidden'));
    }
});

onUnmounted(() => {
    if (toastInstance) {
        toastInstance.dispose();
    }
});
</script>


<template>
    <div
        ref="toastElement"
        :class="toastClasses"
        role="alert"
    >
        <div class="d-flex">
            <div class="toast-body">
                {{ message }}
            </div>
            <button
                type="button"
                class="btn-close btn-close-white me-2 m-auto"
                @click="closeToast"
            />
        </div>
    </div>
</template>
