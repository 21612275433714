import Toast from '@/Components/Toast.vue';
import {createApp, h} from 'vue';
import {router, usePage} from "@inertiajs/vue3";

export const showToast = (message, variant = 'primary') => {
    if (typeof window !== 'undefined') {
        let toastContainer = document.getElementById('toast-container');

        if (!toastContainer) {
            toastContainer = document.createElement('div');
            toastContainer.id = 'toast-container';
            toastContainer.className = 'toast-container position-fixed bottom-0 end-0 p-3';
            document.body.appendChild(toastContainer);
        }

        const toastApp = createApp({
            render() {
                return h(Toast, {message, variant});
            },
        });

        const toastElement = document.createElement('div');
        toastContainer.appendChild(toastElement);
        toastApp.mount(toastElement);
    }
};

export const setupRouterToasts = () => {

};
